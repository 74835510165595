<template>
<div>
  <div class="title-section">
    <p>Your latest applications</p>
  </div>
  <div class="cont-list-offer" v-if="offersRetrieved">

    <job-elem-list
      v-for="offer in offers"
      :key="offer._id"
      :elem="offer.offer"
      @click.native="(event) => openProcessModal(event, offer)"
    >
      <process-progress
        v-if="offer.hasOwnProperty('application')"
        :steps="offer.application.process"
      />
    </job-elem-list>
  </div>
  <loading v-else />
</div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';
import JobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';
// import TalentOffer from './TalentOffer.vue';
import ProcessProgress from '@/components/EmployeeComponents/JobManagement/processPartials/processProgress.vue';
import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';

export default {
  name: 'OfferListTalent',
  components: {
    Loading,
    // TalentOffer,
    JobElemList,
    ProcessProgress,
  },
  computed: {
    ...mapState('talent', ['offers', 'offersRetrieved']),
    ...mapState('user', ['_id']),
  },
  mounted() {
    this.$store.dispatch('talent/getOffers');
  },
  methods: {
    openProcessModal(event, offer) {
      event.stopPropagation();
      this.$modal.show(OfferProgress, {
        offer,
      }, {
        name: 'offer-process',
        width: '80%',
        height: '70vh',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .cont-list-offer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .left{
    .option-title{
      display: none;
    }
  }
</style>
